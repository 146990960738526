<template>
    <div>
      <el-button type="text" @click="open" class="btn"></el-button>
      <div class="img" v-if="showImage">
        <img src="https://www.helloimg.com/i/2024/10/16/670fda454f449.jpg" alt="" class="high-quality-image" />
      </div>
      <div class="div" v-if="showText">
        随地大小便还想休仙，不知天高地厚！！！！！！！！！！！！！！!
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "HomeView",
    data() {
      return {
        showImage: true,
        showText: false
      };
    },
    methods: {
      open() {
        this.$alert('欲练神功，需先拜祖师！！！', {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: `注视祖师五秒`
            });
            setTimeout(() => {
              this.showImage = false;
              this.showText = true; // 5秒后显示文字
            }, 5000); // 5秒后隐藏图片并显示文字
          }
        });
      }
    },
    mounted() {
      this.open(); // 页面加载时自动弹出消息框
    }
  }
  </script>
  
  <style scoped lang="less">
  .btn {
    display: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw; /* 占满视口宽度 */
    height: 100vh; /* 占满视口高度 */
    overflow: hidden; /* 隐藏溢出部分 */
  }
  .high-quality-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 确保图片按比例缩放并裁剪以适应容器 */
    transition: transform 0.3s ease; /* 添加过渡效果 */
  }
  .high-quality-image:hover {
    transform: scale(1.3); /* 鼠标悬停时放大图片 */
}
  .div {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #333;
  }
  
  /* 移动端样式 */
  @media (max-width: 600px) {
    .el-message-box {
      width: 90% !important; /* 调整弹框宽度 */
      left: 5% !important; /* 居中弹框 */
      top: 20% !important; /* 调整弹框位置 */
    }
    .el-message-box__wrapper {
      padding: 0 !important; /* 移除内边距 */
    }
    .el-message-box__header {
      font-size: 16px !important; /* 调整标题字体大小 */
    }
    .el-message-box__content {
      font-size: 14px !important; /* 调整内容字体大小 */
    }
    .el-message-box__btns {
      display: flex;
      justify-content: center;
    }
    .el-button--primary {
      width: 100% !important; /* 按钮宽度占满 */
    }
  }
  </style>