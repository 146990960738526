<template>
    <div>
        <div class="card-content">
            <div class="top-left">网上修仙</div>
            <!-- 导航栏 -->
            <el-menu :default-active="activeIndex" ref="menu" class="el-menu-demo" mode="horizontal"
                @select="handleSelect">
                <el-menu-item index="1">首页</el-menu-item>
                <el-menu-item index="2">登录</el-menu-item>
                <el-menu-item index="3">注册</el-menu-item>
            </el-menu>
            <!-- 登陆注册表单 -->
            <el-card v-if="showCard">
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                    class="demo-ruleForm">
                    <div class="form-title">
                        <div :class="{ active: isLogin, Login: true }" @click="toggleLogin(true)">
                            登陆
                        </div>
                        /
                        <div :class="{ active: !isLogin, Register: true }" @click="toggleLogin(false)">
                            注册
                        </div>
                    </div>
                    <div class="login-content" v-if="isLogin">
                        <el-form-item label="账号" prop="account">
                            <el-input v-model.number="ruleForm.account"></el-input>
                        </el-form-item>
                        <el-form-item label="密码" prop="pass">
                            <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">登陆</el-button>
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                        </el-form-item>
                    </div>

                    <div class="register-content" v-if="!isLogin">
                        <el-form-item label="输入账号" prop="account">
                            <el-input v-model.number="ruleForm.account"></el-input>
                        </el-form-item>
                        <el-form-item label="输入密码" prop="pass">
                            <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')">注册</el-button>
                            <el-button @click="resetForm('ruleForm')">取消</el-button>
                        </el-form-item>
                    </div>
                </el-form>
            </el-card>

            <!-- 文本内容 -->
            <div v-if="showConText" class="conText" v-fade-in>欢迎来到修仙者的频道，这里是你笑出仙气、飞升爆笑的最佳修炼地！我们不教你如何真正修仙，但能让你在笑声中摆脱凡尘的烦恼。快来加入我们吧！<span class="blink-cursor"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoginView",

    data() {
        // 验证账号的回调函数
        var checkAge = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("账号不能为空"));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error("请输密码"));
                }
            }, 1000);
        };
        // 验证密码的回调函数
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        };
        // 验证确认密码的回调函数
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                account: "xiaoLi",
                pass: "admin",
            },
            rules: {
                pass: [{ validator: validatePass, trigger: "blur" }],
                checkPass: [{ validator: validatePass2, trigger: "blur" }],
                account: [{ validator: checkAge, trigger: "blur" }],
            },
            isLogin: true,
            showCard: false, // 控制el-card显示状态
            showConText: true, // 控制conText显示状态
            activeIndex: '', // 控制el-menu的默认激活项
        };
    },
    methods: {
        // 防抖函数
        debounce(func, wait) {
            let timeout;
            return function (...args) {
                const context = this;
                if (timeout) clearTimeout(timeout);
                timeout = setTimeout(() => {
                    func.apply(context, args);
                }, wait);
            };
        },
        // 处理导航栏选择事件
        handleSelect(key, keyPath,e) {
            console.log(key, keyPath);
            if (key === "2") {
                this.isLogin = true;
                this.showCard = true; // 显示el-card
                console.log(e);
                this.showConText = false;
            } else if (key === "3") {
                this.isLogin = false;
                this.showCard = true; // 显示el-card
                this.showConText = false;
                console.log(e);
            } else {
                this.showCard = false; // 隐藏el-card
                console.log(e);
                this.showConText = true;
            }
        },
        // 切换登陆和注册
        toggleLogin(isLogin) {
            this.isLogin = !isLogin;
        },
        // 提交表单
        submitForm(formName) {
            this.showConText = false; // 隐藏conText
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        // 取消按钮
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.showCard = false; // 隐藏el-card
            this.showConText = true;
        },
        // 登陆按钮
        submitForm() {
            this.showConText = false; // 隐藏conText
            this.$message({
                message: "登陆成功",
                type: "success",
            });
            setTimeout(() => {
                this.$router.push("/home");
            }, 1000);
        },
    },
    mounted() {
        // 初始化防抖函数  避免一秒内多次提交表单
        this.debouncedSubmitForm = this.debounce(this.submitForm, 1000);
    },
    directives: {
        "fade-in": {
            inserted(el) {
                const text = el.innerText;
                el.innerText = "";
                for (let i = 0; i < text.length; i++) {
                    setTimeout(() => {
                        const span = document.createElement("span");
                        span.className = "fade-in";
                        span.style.animationDelay = `${i * 0.2}s`; // 调整动画延迟时间
                        span.innerText = text[i];
                        el.appendChild(span);
                    }, i * 200); // 调整setTimeout的延迟时间
                }
            },
        },
    },
};
</script>

<style scoped lang="less">
.card-content {
    background-image: url("https://www.helloimg.com/i/2024/10/16/670fd9b2bcb30.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    /* 设置背景图片，并使内容居中对齐 */
}

.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* 设置登录表单的布局 */
}

.form-title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    /* 设置表单标题的布局 */
}

.Login,
.Register {
    margin: 0 5px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s;
    /* 设置登录和注册按钮的样式 */
}

.Login:hover,
.Register:hover {
    color: #409eff;
    /* 鼠标悬停时字体颜色变化 */
}

.Login::after,
.Register::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100%;
    height: 2px;
    // background-color: #409eff;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
    /* 设置下划线动画效果 */
}

.Login:hover::after,
.Register:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    /* 鼠标悬停时显示下划线 */
}

.active {
    font-weight: bold;
    // color: #409eff;
    /* 设置激活状态的样式 */
}

.el-form-item__label {
    margin-right: 10px;
    /* 调整label和输入框之间的间距 */
}

.top-left {
    position: absolute;
    top: 3%;
    left: 3%;
    padding: 10px;
    font-size: 5vw;
    font-family: "KaiTi", "楷体", serif;
    user-select: none;
    cursor: pointer;
    background: -webkit-linear-gradient(145deg,
            #2eaf8d,
            #2f6ae6 5%,
            #347ba6 10%,
            #16d225 15%,
            #4cc410 20%,
            #bb2c10 25%,
            #2f63a4 30%,
            #28dfd4 35%,
            #ff0000 40%,
            #00ff00 45%,
            #0000ff 50%);
    background: -webkit-linear-gradient(30deg,
            #ff0000,
            #00ff00 55%,
            #0000ff 60%,
            #ffff00 70%,
            #ff00ff 80%,
            #ff0000 90%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: 200% 100%;
    animation: flowCss 5s infinite linear;
    text-align: center;
    font-weight: bold;
    /* 设置顶部左侧文字的样式和动画效果 */
}

@keyframes flowCss {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -400% 0;
    }

    /* 定义文字渐变动画 */
}

.el-menu-demo {
    position: absolute;
    top: 5%;
    right: 4%;
    background-color: transparent;
    color: #fff;
    line-height: 56px;
    border-bottom: none;
    box-shadow: none;
    /* 设置导航栏样式 */
}

.el-menu-demo .el-menu-item {
    color: #fff;
    /* 设置导航栏菜单项的颜色 */
}

.el-menu-demo .el-menu-item:hover {
    background-color:  #fff;
    /* 鼠标悬停时导航栏菜单项的背景颜色 */
}

.el-menu.el-menu--horizontal {
    border: none;
    /* 移除导航栏的边框 */
}

.el-menu-demo .el-menu-item {
    color: #fff;
}

.el-menu-demo .el-menu-item.is-active {
    border-bottom: none !important;
    //   color: #fff !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }

    /* 定义淡入动画 */
}

.fade-in {
    display: inline-block;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    /* 设置淡入效果 */
}

.conText {
    font-family: "KaiTi", "楷体", serif;
    font-size: 2vw;
    // 空两格
    text-indent: 2em;
    margin: auto 50px;
}

.blink-cursor {
    display: inline-block;
    width: 10px;
    height: 20px;
    background-color: black;
    animation: blink 1s infinite;
}
</style>
